import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { checkPropertiesForNull } from "../utils/objectHelper";


const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    pageData: wpPage(slug: { eq: "contact-us" }) {
      seoFieldGroups {
        metaTitle
        metaDescription
        localBusinessSchema
        openGraphDescription
        openGraphTitle
        productSchema
        image {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
      }
      contactPageFieldGroups {
        contactIntroSection {
          heading
          subHeading
          textArea
          formButtonLabel
        }
        contactPageContactSection {
          title
          googleMap
          description
          location {
            title
            target
            url
          }
          mail {
            title
            target
            url
          }
          contactNumber {
            title
            target
            url
          }
        }
        aboveFooterImage1 {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        aboveFooterImage2 {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`);

const {
  pageData: { seoFieldGroups, contactPageFieldGroups },
} = data;

const {
  contactIntroSection,
  contactPageContactSection,
  aboveFooterImage1,
  aboveFooterImage2,
} = contactPageFieldGroups;

const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	//  const html = `<form
	//     action="https://formspree.io/f/"
	//     method="POST">
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">
	//   </div>
	//   <div class="form-group">
	//    <textarea class="form-control bg-light border-0 py-5" rows="3" name="message" placeholder="Message"></textarea>
	//   </div>
	//   // Send Button
	//   <div class="g-recaptcha my-3" data-sitekey=""></div>
	//   <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>
	//   </form>`

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

			 <div style={{ overflow: "hidden" }}>
        <section className="pb-6">
          <Container>
            <Row>
              <Col className="text-center mb-lg-10 mb-5 mt-5">
                <p className="text-text-secondary jost-bold fs-6">
                  {contactIntroSection?.subHeading || "CONTACT US"}
                </p>
                {contactIntroSection.heading && (
                  <h1 className=" display-4 ">
                    {contactIntroSection?.heading}
                  </h1>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="pe-lg-6 pb-4 pb-lg-0" lg={6}>
                <SafeHtmlParser htmlContent={contactIntroSection?.textArea} />
              </Col>
              <Col lg={6}>
                <ContactForm
                  buttonText={contactIntroSection?.formButtonLabel}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {contactPageContactSection &&
          !checkPropertiesForNull(contactPageContactSection, ["title"]) && (
            <section>
              <Container fluid className="px-0 mx-0">
                <Row className="align-items-center bg-primary">
                  {contactPageContactSection.googleMap && (
                    <Col className="bg-white px-0 py-0" lg={6}>
                      <iframe
                        style={{ height: "75vh" }}
                        src={contactPageContactSection.googleMap}
                        frameBorder="0"
                        aria-hidden="false"
                        title="google-map"
                        width="100%"
                      ></iframe>
                    </Col>
                  )}

                  <Col className=" px-md-7 py-5 ps-5 pe-4 py-lg-0" lg={6}>
                    {" "}
                    <h2 className="text-white display-4 pb-4 ">
                      {contactPageContactSection?.title}
                    </h2>
                    <div className="content pb-4 content-white">
                      <SafeHtmlParser
                        htmlContent={contactPageContactSection?.description}
                      />
                    </div>
                    {contactPageContactSection.location && (
                      <a
                        className="white-link d-flex fs-5 align-items-center  pb-3"
                        target="_blank"
                        rel="noreferrer"
                        href={contactPageContactSection.location?.url}
                      >
                        <div style={{ minWidth: "33px" }}>
                          <MdLocationOn
                            style={{ minWidth: "33px" }}
                            className="me-3 text-white display-5"
                          />
                        </div>
                        {contactPageContactSection.location?.title}
                      </a>
                    )}
                    {contactPageContactSection.contactNumber && (
                      <a
                        className="white-link d-flex fs-5 align-items-center  pb-3"
                        href={contactPageContactSection.contactNumber?.url}
                      >
                        <div style={{ minWidth: "33px" }}>
                          <MdPhone
                            style={{ minWidth: "33px" }}
                            className="me-3 text-white display-5"
                          />
                        </div>
                        {contactPageContactSection.contactNumber?.title}
                      </a>
                    )}
                    {contactPageContactSection.mail && (
                      <a
                        className="white-link d-flex fs-5 align-items-center "
                        href={contactPageContactSection.mail?.url}
                      >
                        <div style={{ minWidth: "33px" }}>
                          <MdEmail
                            style={{ minWidth: "33px" }}
                            className="me-3 text-white display-5"
                          />
                        </div>
                        {contactPageContactSection.mail?.title}
                      </a>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {aboveFooterImage1 && (
          <>
            <section className="d-lg-none">
              <Container fluid className="px-0 mx-0">
                <Row>
                  <Col xs={12}>
                    <GatsbyImage
                      className="w-100"
                      image={
                        aboveFooterImage1?.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={aboveFooterImage1?.node?.altText}
                    />
                  </Col>
                  <Col xs={12}>
                    <GatsbyImage
                      className="w-100"
                      image={
                        aboveFooterImage2?.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={aboveFooterImage2?.node?.altText}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
            <section
              style={{ height: "80vh" }}
              className="position-relative w-100 d-none d-lg-block"
            >
              <div className="h-100 position-absolute start-0 main-image-left">
                <GatsbyImage
                  className="h-100 w-100"
                  image={
                    aboveFooterImage1?.node?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={aboveFooterImage1?.node?.altText}
                />
              </div>
              <div className="h-100 position-absolute end-0 main-image-right">
                <GatsbyImage
                  className="h-100 w-100"
                  image={
                    aboveFooterImage2?.node?.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={aboveFooterImage2?.node?.altText}
                />
              </div>
            </section>
          </>
        )}
      </div>
		</Layout>
	);
};

export default ContactUsPage;
